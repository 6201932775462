import React from 'react';
import '../../App.css';
import { Typography, Box, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import Link from '@mui/material/Link';
import { theme3, theme2  } from '../Theme.js';

export default function About() {
  
  return (
    <div>
    <React.Fragment>
    <ThemeProvider theme={theme2}>
    <CssBaseline />
    <Box>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography display="block" align="center" fontSize={50}>About</Typography>
        <Typography> &nbsp; </Typography>
        <div>
          <ThemeProvider theme={theme2}>
          <CssBaseline/>
          <Typography display="block" align="center" fontSize={25} variant="caption">Geraldo López</Typography>
          <Typography display="block" align="center" fontSize={25} variant="caption">Marc Siaca</Typography>
          <Typography> &nbsp; </Typography>
          <Typography> &nbsp; </Typography>
          <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>
    
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>

  </ThemeProvider>  
    </div>
    </Box>
    </ThemeProvider>
    </React.Fragment>
</div>
  );
}