const baseAPI = '/api';

const rsvpService = {
  get() {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/rsvp`)
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },

  getRsvpByKey(randomKey) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/rsvp/${randomKey}`)
        .then(response => {
          return response.json();
        })
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },

  create(rsvp) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/rsvp`, {
        method: 'POST',
        body: JSON.stringify(rsvp),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(result => result.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  },

  update(rsvp) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/rsvp`, {
        method: 'PUT',
        body: JSON.stringify(rsvp),
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        }
      })
        .then(result => {
          resolve(result);
        })
        .catch(err => {
          reject(err);
        });
    });
  },

  destroy(rsvp) {
    return new Promise((resolve, reject) => {
      fetch(`${baseAPI}/rsvp/${rsvp.id}`, { method: 'DELETE' })
        .then(response => response.json())
        .then(json => resolve(json))
        .catch(err => {
          reject(err);
        });
    });
  }
};

export default rsvpService;