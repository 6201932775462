import React, { Component } from 'react';
import FindRsvp from './FindRsvp';
import RsvpForm from './RsvpForm';
import api from '../service';


class RsvpController extends Component {
  constructor() {
    super();

    this.state = {
      rsvpController: [],
      addingRsvp: true,
      selectedRsvp: { id: '', name: '', email: '', phoneNumber: '', mailingAddress: '', attending: '', partyNumber: '', maxPartyNumber: '', meal: '', songRequest: '', advice: '', openMessage: '', randomKey: '', meal0: '', meal1: '', meal2: '', meal3: '', meal4: '', meal5: '', meal6: '', meal7: '', meal8: '', meal9: '', meal10: '', meal11: '', guestName0: '', guestName1: '', guestName2: '', guestName3: '', guestName4: '', guestName5: '', guestName6: '', guestName7: '', guestName8: '', guestName9: '', guestName10: '', guestName11: ''},
    };

    this.handleSave = this.handleSave.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleFindRsvpByKey = this.handleFindRsvpByKey.bind(this);
  }

  // invoked immediately after a component is mounted
  // componentDidMount() {
  //   api.get().then(json => this.setState({ rsvpController: json }));
  // }

  handleSelect(rsvp) {
    this.setState({ selectedRsvp: rsvp });
  }

  handleDelete(event, rsvp) {
    event.stopPropagation();

    api.destroy(rsvp).then(() => {
      let rsvpController = this.state.rsvpController;
      rsvpController = rsvpController.filter(h => h !== rsvp);
      this.setState({ rsvpController: rsvpController });

      if (this.selectedRsvp === rsvp) {
        this.setState({ selectedRsvp: null });
      }
    });
  }

  handleFindRsvpByKey() {
    let rsvpController = this.state.rsvpController;
    if (this.state.addingRsvp && this.state.selectedRsvp.randomKey !== null && this.state.selectedRsvp.randomKey !== '') {
      api
        .getRsvpByKey(this.state.selectedRsvp.randomKey)
        .then(response => {
          if (response.error) {
            throw new Error(response.error); // Throw the specific error message
          } else {
            return response;
          }
        })
        .then(result => {
          rsvpController.push(result);
          this.setState({
            rsvpController: rsvpController,
            selectedRsvp: null,
            addingRsvp: false
          });
        })
        .catch(err => {
          //console.log(err); // Handle the error
          window.alert(err.message); // Display the error message to the user
        });
    }
  }

  handleCancel() {
    window.location.reload();
  }

  handleSave() {
    let rsvpController = this.state.rsvpController;

    if (this.state.addingRsvp) {
      api
        .create(this.state.selectedRsvp)
        .then(result => {
          rsvpController.push(this.state.selectedRsvp);
          this.setState({
            rsvpController: rsvpController,
            selectedRsvp: null,
            addingRsvp: false
          });
        })
        .catch(err => {
          //console.log(err);
        });
    } else {
      api
        .update(this.state.selectedRsvp)
        .then(() => {
          this.setState({ selectedRsvp: null });
        })
        .catch(err => {});
    }
  }

  handleOnChange(event) {
    let selectedRsvp = this.state.selectedRsvp;
    selectedRsvp[event.target.name] = event.target.value;
    this.setState({ selectedRsvp: selectedRsvp });
  }

  render() {
    return (
      <div>
        <ul className="rsvpController">
              <RsvpForm/>
        </ul>
      </div>
    );
  }
}


export default RsvpController;