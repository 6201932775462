import React from 'react';
import { useState, useEffect } from "react";
import RsvpFormInput from "./RsvpFormInput";
import './RsvpForm.css'
import { useNavigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import api from '../service';
import { theme2 } from './Theme.js';
import { useParams } from 'react-router-dom';
import { useLocation } from "react-router-dom";

const RsvpForm = (props) => {
  const { rsvp } = props;
  const navigate = useNavigate();
  const { randomKey } = useParams();
  const [guestNameErrors, setGuestNameErrors] = useState([]);
  const [isDirectAccess, setIsDirectAccess] = useState(false);
  const [values, setValues] = useState({
    id: rsvp? rsvp.id : "",
    name: rsvp && rsvp.name ? rsvp.name : "",
    businessName: "",
    referralName: "",
    instagram: "",
    website: "",
    email: rsvp && rsvp.email ? rsvp.email : "",
    phoneNumber: rsvp && rsvp.phoneNumber ? rsvp.phoneNumber : "",
    mailingAddress: rsvp && rsvp.mailingAddress ? rsvp.mailingAddress : "",
    attending: rsvp && rsvp.attending ? rsvp.attending : "",
    partyNumber: rsvp && rsvp.partyNumber ? rsvp.partyNumber : "",
    maxPartyNumber: rsvp && rsvp.maxPartyNumber ? rsvp.maxPartyNumber : "",
    meal: rsvp && rsvp.meal ? rsvp.meal : "",
    songRequest: rsvp && rsvp.songRequest ? rsvp.songRequest : "",
    advice: rsvp && rsvp.advice ? rsvp.advice : "",
    openMessage: rsvp && rsvp.openMessage ? rsvp.openMessage : "",
  });

  useEffect(() => {
    if (randomKey)
    {
      fetchRsvpData(randomKey);
      setIsDirectAccess(true);
    }
  }, [randomKey]);

  const fetchRsvpData = (randomKey) => {
    api.getRsvpByKey(randomKey)
      .then(data => {
        setValues(prevValues => ({
          ...prevValues,
          ...data,
          partyNumber: data.partyNumber // Include partyNumber in the fetched data
        }));
      })
  };
  const [errors, setErrors] = React.useState({
    email: false,
    name: false,
    mailingAddress: false,
    phoneNumber: false,
    songRequest: false,
    advice: false,
    openMessage: false,
  });

  const { pathname } = useLocation();

  useEffect(() => {
    const body = document.querySelector('#root');
    body.scrollIntoView({
        behavior: 'instant'
    }, 500)
}, [pathname]);

  const emailRegex = /^\S+@\S+\.\S+$/;
  const textRegex = /^[\w\u00C0-\u024F?!\s.,-]*$/;
  const numberRegex = /^(\+[-\d]+|\d[-\d]*)$/;

  const handleSubmit = (e) => {
    e.preventDefault();

    let hasErrors = false;

    if (!emailRegex.test(values.email)) {
      setErrors(errors => ({ ...errors, email: true }));
      hasErrors = true;
    }
    if(!textRegex.test(values.name)){
      setErrors(errors => ({ ...errors, name: true }));
      hasErrors = true;
    }
    if(!textRegex.test(values.mailingAddress)){
      setErrors(errors => ({ ...errors, mailingAddress: true }));
      hasErrors = true;
    }
    if(!numberRegex.test(values.phoneNumber)){
      setErrors(errors => ({ ...errors, phoneNumber: true }));
      hasErrors = true;
    }
    if(!textRegex.test(values.songRequest)){
      setErrors(errors => ({ ...errors, songRequest: true }));
      hasErrors = true;
    }
    if(!textRegex.test(values.advice)){
      setErrors(errors => ({ ...errors, advice: true }));
      hasErrors = true;
    }
    if(!textRegex.test(values.openMessage)){
      setErrors(errors => ({ ...errors, openMessage: true }));
      hasErrors = true;
    }
    [...Array.from({ length: values.maxPartyNumber }, (_, index) => index)].forEach((_, index) => {
      const guestName = values[`guestName${index}`];
      if (!textRegex.test(guestName)) {
        setGuestNameErrors((errors) => {
          const updatedErrors = [...errors];
          updatedErrors[index] = true;
          return updatedErrors;
        });
        hasErrors = true;
      }
    });
    

    // If there are errors, exit the function
    if (hasErrors) {
      return;
    }
    
    api
      .update(values)
      .then(result => {
        navigate('/success');
      })
      .catch(err => {
        //console.log(err);
      });
  };

  const onChange = (e) => {
    setValues({ ...values, [e.target.name]: e.target.value });
    setErrors({ ...errors, [e.target.name]: false }); // Reset the error when the user types

    if (e.target.name.startsWith("guestName")) {
      const index = parseInt(e.target.name.match(/\d+$/)[0]);
      setGuestNameErrors((errors) => {
        const updatedErrors = [...errors];
        updatedErrors[index] = false;
        return updatedErrors;
      });
    } else {
      setErrors({ ...errors, [e.target.name]: false });
    }
  };

  const handleCancel = (e) => {
    if (randomKey)
    {
      navigate('/rsvp');
    }
    else
    {
      window.location.reload();
    }
  };

  return (
    <div className='formRsvp'>
      <React.Fragment>
      <div className={`${isDirectAccess ? 'directFormText' : 'formText'}`}>
        <ThemeProvider theme={theme2}>
          <CssBaseline />
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
          <Typography align="center">
            Contact
          </Typography>
        </ThemeProvider>

        <Typography> &nbsp; </Typography>
        <ThemeProvider theme={theme2}>
        <Typography align="center" variant="h6" fontWeight="bold">
          {values.name}
          </Typography>
          <Typography align="center">
          Let's work together!
          </Typography>
        </ThemeProvider>
        </div>
      <div className={`${isDirectAccess ? 'directFormApp' : 'formApp'}`}>
        <form onSubmit={handleSubmit}>
          <Typography> &nbsp; </Typography>
          <RsvpFormInput
            id={1}
            name="name"
            type="text"
            placeholder="Name"
            label="Name *"
            required={true}
            value={values.name}
            onChange={onChange}
            errormessage={errors.name ? 'Solamente se permiten letras y números.' : ''}
          />
          <RsvpFormInput
            id={1}
            name="businessName"
            type="text"
            placeholder="Business Name"
            label="Business Name *"
            required={true}
            value={values.businessName}
            onChange={onChange}
            errormessage={errors.name ? 'Solamente se permiten letras y números.' : ''}
          />
          <RsvpFormInput
            id={1}
            name="website"
            type="text"
            placeholder="Website"
            label="Website"
            required={false}
            value={values.website}
            onChange={onChange}
            errormessage={errors.name ? 'Solamente se permiten letras y números.' : ''}
          />
          <RsvpFormInput
            id={1}
            name="instagram"
            type="text"
            placeholder="Instagram"
            label="Instagram"
            required={false}
            value={values.instagram}
            onChange={onChange}
            errormessage={errors.name ? 'Solamente se permiten letras y números.' : ''}
          />
          <RsvpFormInput
            id={2}
            name="email"
            type="text"
            placeholder="Email"
            label="Email *"
            required={true}
            value={values.email}
            onChange={onChange}
            errormessage={errors.email ? 'Not a valid email address.' : ''}
          />
          <RsvpFormInput
            id={3}
            name="phoneNumber"
            type="text"
            placeholder="Número de teléfono"
            label="Número de teléfono *"
            required={true}
            value={values.phoneNumber}
            onChange={onChange}
            errormessage={errors.phoneNumber ? 'Debe ser un número de teléfono correcto. Solo se permiten números y guiones.' : ''}
          />
          <RsvpFormInput
            key={0}
            id={5}
            name="attending"
            type="select"
            placeholder="How did you hear about us?"
            label="How did you hear about us? *"
            required={true}
            value={values.attending}
            onChange={onChange}
          />
          {values.attending === 'Referral' && (
            <RsvpFormInput
              id={1}
              name="referralName"
              type="text"
              placeholder="Who referred you?"
              label="Who referred you? *"
              required={true}
              value={values.referralName}
              onChange={onChange}
              errormessage={errors.name ? 'Only alphanumeric characters.' : ''}
          />
          )}
          <RsvpFormInput
            key={3}
            id={9}
            name="advice"
            type="text"
            placeholder="Project Deadline"
            label="Project Deadline"
            required={false}
            value={values.advice}
            onChange={onChange}
            errormessage={errors.advice ? 'Solamente se permiten letras y números.' : ''}
          />
          <RsvpFormInput
            key={4}
            id={10}
            name="openMessage"
            type="text"
            placeholder="Open Message"
            label="Open Message"
            required={false}
            value={values.openMessage}
            onChange={onChange}
            errormessage={errors.openMessage ? 'Solamente se permiten letras y números.' : ''}
          />
          <button>Submit</button>
          <button type="button" onClick={handleCancel}>Cancel</button>
          <Typography> &nbsp; </Typography>
          <Typography> &nbsp; </Typography>
          <Typography> &nbsp; </Typography>
          <Typography> &nbsp; </Typography>
          <Typography> &nbsp; </Typography>
          <Typography> &nbsp; </Typography>
          <Typography> &nbsp; </Typography>
          <Typography> &nbsp; </Typography>
          <Typography> &nbsp; </Typography>
        </form>
      </div>
      </React.Fragment>
    </div>
  );
};

export default RsvpForm;