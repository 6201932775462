import React from 'react';
import Confetti from "react-confetti";
import { useState, useEffect } from "react";
import { Typography, Box, CssBaseline} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { theme2 } from '../Theme.js';
import ImageListItem from "@mui/material/ImageListItem";
import { CircleLoader } from "react-spinners";
import './RsvpSuccess.css';

export default function RsvpSuccess() {
  const [loading, setLoading] = React.useState(true);
  const handleImageLoad = () => {
    setLoading(false);
  };
  const [pieces, setPieces] = useState(350);

  const stopConfetti = () => {
    setTimeout(() => {
      setPieces(0);
    }, 3000);
  };

  useEffect(() => {
    stopConfetti();
  }, []);
  return (
    <React.Fragment>
    <div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }} >
      <div>
        <ThemeProvider theme={theme2}>
            <CssBaseline/>
            <Typography> &nbsp; </Typography>
            <Typography> &nbsp; </Typography>
            <Typography> &nbsp; </Typography>
            <Typography display="block" align="center" fontSize={25}>
            ¡Gracias por completar el formulario! 
            </Typography>
            <Box
        sx={{
          display: "grid",
          gap: 2,
          mx: 'auto',
          marginBottom: '20px', // Add margin to create space at the bottom,
          justifyContent: 'center', // Center horizontally
          alignItems: 'center', // Center vertically
          [theme2.breakpoints.up('mobile')]: {
            width: 300,
          },
          [theme2.breakpoints.up('bigMobile')]: {
            width: 355,
          },
          [theme2.breakpoints.up('table')]: {
            width: 600,
          },
          [theme2.breakpoints.up('desktop')]: {
            width: 900
          }
        }}
      >
          <Box>
          <div className='image'>
          <ImageListItem
           cols={1}
           rows={1}
           sx={{
              opacity: '.85',
              transition: 'opacity .3s linear',
              cursor: 'cursor',
              '&:hover': {opacity:1}
           }}
           
           >
          <img src = 'https://gkwedding2923images-g7g5ejccg0eraedu.z01.azurefd.net/images/photo32.jpg' alt="home" loading="lazy"preload="auto"
                  onLoad={handleImageLoad} style={{ opacity: loading ? 0 : 1 }}/>
          {loading && (
                  <div className="spinner-container">
                    <CircleLoader color="#CB8A36" loading size={100}  />
                  </div>
          )}
          </ImageListItem>
          </div>
        </Box>
      </Box>


        </ThemeProvider>      
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
      </div>
      <Confetti gravity={0.2} numberOfPieces={pieces} colors={['white', '#CB8A36', '#E6BE66', '#C79A61']}/>
    </div>
    </React.Fragment>
  );
}