import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import LocalCafeIcon from '@mui/icons-material/LocalCafe';
import {Link} from "react-router-dom";
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListItemIcon from '@mui/material/ListItemIcon';
import HomeIcon from '@mui/icons-material/Home';
import RsvpIcon from '@mui/icons-material/Rsvp';
import PhotoLibraryIcon from '@mui/icons-material/PhotoLibrary';
import EventIcon from '@mui/icons-material/Event';
import LocalLibraryIcon from '@mui/icons-material/LocalLibrary';
import ConnectingAirportsIcon from '@mui/icons-material/ConnectingAirports';
import QuizIcon from '@mui/icons-material/Quiz';
import { theme4, theme5 } from './Theme.js';
import { CssBaseline} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import './Navbar.css'; // Import your CSS file here

const pages = ['Menu', 'About', 'Services', 'Contact', 'FAQ'];
const ResponsiveAppBar = () => {
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const theme = useTheme();
  const handleOpenDrawer = () => {
    setOpenDrawer(true);
  };

  const handleCloseDrawer = () => {
    setOpenDrawer(false);
  };

  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const mobilePages = isMobile ? ['Home', ...pages] : pages;

  // Helper function to get the icon component based on the page value
  const getPageIcon = (page) => {
  switch (page) {
    case 'Home':
      return <HomeIcon />;
    case 'Rsvp':
      return <RsvpIcon />;
    case 'Fotos':
      return <PhotoLibraryIcon />;
    case 'Eventos':
      return <EventIcon />;
    case 'Historia':
      return <LocalLibraryIcon />;
    case 'Viajeros':
      return <ConnectingAirportsIcon />;
    case 'FAQ':
      return <QuizIcon />;
    // Add more cases for other pages and their respective icons
    default:
      return null;
    }
  };

  return (
    <AppBar elevation={0} position={isMobile ? "fixed" : "static"} style={{ background: 'transparent' }}>
      <Container maxWidth="xl" sx={{ display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'flex-start' : 'center', }}>
        <Toolbar disableGutters style={{ alignItems: 'center' }}>
          <CssBaseline/>
          <a href="/" style={{ textDecoration: "none", color: "inherit", position: 'relative', top: '1px' }} className="icon">
          <LocalCafeIcon sx={{ display: { xs: 'none', md: 'flex' }, mr: 2, color: "black", fontSize: 26, marginRight: 1, // Adjust the marginRight value as desired
            marginTop: { xs: 0, md: '7px' }
           }} />
          </a>
          <ThemeProvider theme={theme5}>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              fontSize: 16,
              mr: 2,
              display: { xs: 'none', md: 'none', sm:'none', lg:'flex', xl: 'flex' },
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'black',
              textDecoration: 'none',
            }}
          >
            Celina's Cafe
          </Typography>
          </ThemeProvider>
          
          {isMobile ? (
            <ThemeProvider theme={theme4}>
            <CssBaseline />
            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex'} }}>
              <IconButton
                size="large"
                aria-label="open drawer"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenDrawer}
                style={{ color: "black" }}
                >
                <MenuIcon />
              </IconButton>
              <Drawer anchor="left" open={openDrawer} onClose={handleCloseDrawer}>
                <List>
                  {mobilePages.map((page) => (
                    <ListItem key={page} onClick={handleCloseDrawer} button component={Link} to={`/${page}`}>
                      <ListItemIcon sx={{ color: 'black' }}>
                        {/* Add the appropriate icon component for each page */}
                        {getPageIcon(page)}
                      </ListItemIcon>
                      <ListItemText primary={page} sx={{ '& a': { textDecoration: 'none', color: 'inherit' } }} />
                    </ListItem>
                  ))}
                </List>
              </Drawer>
            </Box>
            </ThemeProvider>
          ) : (
          <Box sx={{px:0, margin: 0, flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'center', // Add centering for the navbar buttons
        }}>
            <ThemeProvider theme={theme4}>
            {pages.map((page) => (
              <Button
              key={page}
              component={Link}
              to={`/${page}`}
              sx={{
                my: 2,
                color: 'black',
                margin: 3,
                display: 'block',
                textDecoration: 'none',
                borderRadius: '4px', // Add border radius to the button
                ':hover': {
                  textDecoration: 'underline',
                  boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.2)', // Add your desired box shadow style
                },
              }}
            >
              {page}
            </Button>
            ))}
            </ThemeProvider>
          </Box>
          )}
        </Toolbar>
      </Container>
    </AppBar>
  );
};
export default ResponsiveAppBar;