import React from 'react';
import '../../App.css';
import { Typography, Box, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { theme2 } from '../Theme.js';

export default function Travel() {

  return (
    <div>
      <React.Fragment>
    <ThemeProvider theme={theme2}>
    <CssBaseline />

    <Box>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>      
        <Typography display="block" align="center" fontSize={50} >
        Services
      </Typography>
      <div>
          <ThemeProvider theme={theme2}>
          <CssBaseline/>
          <Typography> &nbsp; </Typography>
          <Typography> &nbsp; </Typography>
          <Typography display="block" align="center" fontSize={25} variant="caption">Read more about our services</Typography>
          <Typography> &nbsp; </Typography>


      <Typography display="block" align="center" variant="despedidas">Let us know if you have any questions</Typography>

      <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>
    
  <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
</ThemeProvider>
</div>
    </Box>
    </ThemeProvider>
    </React.Fragment>
</div>
  );
}