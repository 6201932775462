import React from 'react';
import './App.css';
import Navbar from './components/Navbar';
import { Route, Routes } from 'react-router';
import About from './components/pages/About';
import Gallery from './components/pages/Gallery';
import Story from './components/pages/Story';
import Services from './components/pages/Services';
import Home from './components/pages/Home';
import Faq from './components/pages/Faq';
import RsvpController from './components/RsvpController';
import RsvpSuccess from './components/pages/RsvpSuccess';
import RsvpFormPage from './components/RsvpFormPage';
//import ProtectedRoutes from './ProtectedRoutes';
//import LoginPage from './components/pages/LoginPage';
import { createContext, useState } from "react";
//import LogOut from './components/pages/LogOut';
import ScrollToTop from './components/ScrollTop';

export const UserContext = createContext();

function App() {
  const [user, setUser] = useState({ /*loggedIn: false*/ });
  return (
    <UserContext.Provider value={{ user, setUser }}>
    <div className='App'>
      <Navbar />
      <ScrollToTop/>
      <Routes>
        <Route path="/" element={<Home />} />
        {/*<Route element={<ProtectedRoutes />}> */}
          <Route path="/home" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/menu" element={<Gallery />} />
          <Route path="/contact" element={<RsvpController />} />
          <Route path="/rsvp/:randomKey" element={<RsvpFormPage />} />
          <Route path="/portfolio" element={<Story />} />
          <Route path="/services" element={<Services />} />
          <Route path="/faq" element={<Faq />} />
          <Route path="/success" element={<RsvpSuccess />} />
          {/*<Route path="/logOut" element={<LogOut />} /> */}
        {/*</Route>*/}
        <Route path="*" element={<Home/>} />
</Routes>
      <div>
          <p style={{ fontSize: '12px' }}>
          &copy; 2023 LDO WEB. WEBSITE DESIGNED & DEVELOPED BY LDO WEB.</p>
        </div>
    </div>
    </UserContext.Provider>
  );
}

export default App;
