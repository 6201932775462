import React from 'react';
import '../../App.css';
import Faq from "react-faq-component";
import './Faq.css';
import { Typography, CssBaseline} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { theme2, theme3 } from '../Theme.js';

const data = {
    rows: [
        {
            title: "¿A que hora abren?",
            content: `Estamos abierto de 9am a 4pm.`,
        },
        
        {
            title: "¿Tienen estacionamiento disponible?",
            content: `Si, contamos con un lote para 5 vehiculos.`,
        },
        {
            title: "¿Donde estan localizados?",
            content: 'San Juan, PR.',
        },
        {
          title: "¿Que tipo de cafe venden?",
          content: `Todo tipo de cafe.`,
      }
    ],
};

const styles = {
    title: 'faq-title',
    bgColor: 'transparent',
    titleTextColor: 'black',
    rowTitleColor: 'black',
    rowContentColor: 'black',
    arrowColor: "black",

  };

const config = {
     animate: true,
     //arrowIcon: "GK",
    tabFocus: true
};

export default function FaqPage() {

  return (
    <React.Fragment>

    <ThemeProvider theme={theme2}>
            <CssBaseline />
            <Typography> &nbsp; </Typography>
            <Typography> &nbsp; </Typography>
            <Typography> &nbsp; </Typography>
            <Typography display="block" align="center" fontSize={50}>FAQ</Typography>
            <Typography> &nbsp; </Typography>
    <div className="main">
      <div className="faq">
        <ThemeProvider theme={theme2}>
            <CssBaseline />
        <Faq
            data={data}
            styles={styles}
            config={config}
        />
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        </ThemeProvider>
        </div>
    </div>
    </ThemeProvider>
    </React.Fragment>

);
}