import React from 'react';
import '../../App.css';
import { useState, useEffect, useRef } from "react";
import { Typography, Box, CssBaseline} from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { theme1, theme2, theme3 } from '../Theme.js';
import ImageListItem from "@mui/material/ImageListItem";
import { CircleLoader } from "react-spinners";
import { motion } from 'framer-motion';

export default function Home() {
    const CustomTypography = motion(Typography);
    const text = "San Juan, Puerto Rico";
    const colors = ['#BF5F4F', '#E07436', '#E5C95C', '#85925C', '#397692', '#1D3B53']; // Add your desired colors here

    const [hoveredIndex, setHoveredIndex] = useState(null);
    const [letterColors, setLetterColors] = useState(Array.from(text).map(() => '#000000'));
    const timeoutRefs = useRef([]);

    const handleHover = (index) => {
      setHoveredIndex(index);
      clearTimeout(timeoutRefs.current[index]);
      setLetterColors((prevColors) =>
        prevColors.map((color, i) => (i === index ? colors[i % colors.length] : color))
      );
    };

    const handleLeave = (index) => {
      timeoutRefs.current[index] = setTimeout(() => {
        setLetterColors((prevColors) =>
          prevColors.map((color, i) => (i === index ? '#000000' : color))
        );
      }, 800);
    };

    useEffect(() => {
      timeoutRefs.current = Array.from(text).map(() => null);
      return () => {
        timeoutRefs.current.forEach((ref) => clearTimeout(ref));
      };
    }, []);

    const [loading, setLoading] = React.useState(true);
    const handleImageLoad = () => {
      setLoading(false);
    };

    return (
        <React.Fragment>
        <ThemeProvider theme={theme2}>
            <CssBaseline />
            <Box>
            <Typography> &nbsp; </Typography>
            <Typography> &nbsp; </Typography>

            <Typography display="block" align="center" fontSize={80}>Celina's Cafe</Typography>
            <Typography> &nbsp; </Typography>
            <div>
            <ThemeProvider theme={theme2}>
            <CssBaseline/>
            <div>
            <ThemeProvider theme={theme2}>
              <CssBaseline />
              {Array.from(text).map((letter, index) => (
                <motion.span
                  key={index}
                  style={{ color: letterColors[index], fontSize: 40, transition: 'color 0.3s ease-in-out'}}
                  onMouseEnter={() => handleHover(index)}
                  onMouseLeave={() => handleLeave(index)}
                >
                  {letter}
                </motion.span>
              ))}
            </ThemeProvider>
          </div>
            </ThemeProvider>
            </div>
            <Typography> &nbsp; </Typography>   
            </Box>
        </ThemeProvider>

        <ThemeProvider theme={theme1}>
        <Box
        sx={{
          display: "grid",
          gap: 2,
          mx: 'auto',
          marginBottom: '20px', // Add margin to create space at the bottom,
          justifyContent: 'center', // Center horizontally
          alignItems: 'center', // Center vertically
          [theme1.breakpoints.up('mobile')]: {
            width: 300,
          },
          [theme1.breakpoints.up('bigMobile')]: {
            width: 355,
          },
          [theme1.breakpoints.up('table')]: {
            width: 600,
          },
          [theme1.breakpoints.up('desktop')]: {
            width: 900
          }
        }}
      >
          <Box>
          <ImageListItem
           cols={1}
           rows={1}
           sx={{
              opacity: '.85',
              transition: 'opacity .3s linear',
              cursor: 'cursor',
              '&:hover': {opacity:1}
           }}
           
           >
          <img src = 'https://celinascafe.blob.core.windows.net/images/CelinasCafeLogo.png' alt="home" loading="lazy" preload="auto"
                  onLoad={handleImageLoad} style={{ opacity: loading ? 0 : 1 }}/>
          {loading && (
                  <div className="spinner-container">
                    <CircleLoader color="#CB8A36" loading size={100}   />
                  </div>
          )}
          </ImageListItem>
        </Box>
      </Box>
      <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
    </ThemeProvider>

        </React.Fragment>
    );
}