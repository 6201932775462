import React from 'react';
import { ThemeProvider } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import CssBaseline from '@mui/material/CssBaseline';
import { theme2 } from './Theme.js';
import './FindRsvp.css'
import { useState } from "react";


const FindRsvp = props => {
  const [searchClicked, setSearchClicked] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setSearchClicked(true);
    props.onSave();
  };

  if (props.selectedRsvp) {
    return (
      <div className="formAppRsvp">
      <form>
      <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>
      <ThemeProvider theme={theme2}>
        <CssBaseline />
        <Typography align="center" fontSize={20} >
          Contact
        </Typography>
        </ThemeProvider>
        <Typography> &nbsp; </Typography>
        <ThemeProvider theme={theme2}>
        <Typography align="center">
          Fill the form below to contact us.
        </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        </ThemeProvider>
    <div className={`formInputRsvp ${!props.selectedRsvp.randomKey && searchClicked ? 'invalid' : ''}`}>
      <label>Email</label>
        <input
          name="randomKey"
          value={props.selectedRsvp.randomKey}
          placeholder="Email"
          onChange={props.onChange}
          required={true}
        />
        {!props.selectedRsvp.randomKey && searchClicked && (
          <span>Incorrect email</span>
        )}
    </div>
        <button onClick={handleSubmit}>Contact</button>
        <button onClick={props.onCancel}>Cancel</button>
        </form>
      </div>
    );
  } else {
    return <div />;
  }
};

export default FindRsvp;