import React from 'react';
import '../../App.css';
import { Typography, Box, CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './Story.css';
import { theme2, theme3 } from '../Theme.js';
import { useMediaQuery } from '@mui/material';

const get_image_url = (imageId) => {
  return `https://gkwedding2923images-g7g5ejccg0eraedu.z01.azurefd.net/images/${imageId}.svg`;
}

const itemData = [
  {
    img: 'photo10',
    rows: 2,
    cols: 2,
  },
  {
    img: 'melbourne',
    rows: 2,
    cols: 2,
  },
  {
    img: 'melbourne-mobile',
    rows: 2,
    cols: 2,
  },
  {
    img: 'rainier',
    rows: 2,
    cols: 2,
  },
  {
    img: 'rainier-mobile',
    rows: 2,
    cols: 2,
  },
  {
    img: 'casino',
    rows: 2,
    cols: 2,
  },
  {
    img: 'casino-mobile',
    rows: 2,
    cols: 2,
  },
  {
    img: 'Roadtrip1',
    rows: 2,
    cols: 2,
  },
  {
    img: 'Roadtrip-mobile',
    rows: 2,
    cols: 2,
  },
  {
    img: 'continued',
    rows: 2,
    cols: 2,
  },
  {
    img: 'continued-mobile',
    rows: 2,
    cols: 2,
  },
  {
    img: 'salamanca',
    rows: 2,
    cols: 2,
  },
  {
    img: 'salamanca-mobile',
    rows: 2,
    cols: 2,
  },
  {
    img: 'toabaja',
    rows: 2,
    cols: 2,
  },
  {
    img: 'toabaja-mobile',
    rows: 2,
    cols: 2,
  },
  {
    img: 'distancia',
    rows: 2,
    cols: 2,
  },
  {
    img: 'distancia-mobile',
    rows: 2,
    cols: 2,
  },
  {
    img: 'barcelona',
    rows: 2,
    cols: 2,
  },
  {
    img: 'barcelona-mobile',
    rows: 2,
    cols: 2,
  }
];

//const image1 = get_image_url(itemData[0].img);
export default function Story() {

  const isMobile = useMediaQuery(theme3.breakpoints.down('large'));
  return (
    <div>
    <React.Fragment>
    <ThemeProvider theme={theme3}>
    <CssBaseline />
      <Box>
      <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>
      <Typography> &nbsp; </Typography>

      <Typography display="block" align="center" fontSize={50} >
        Nuestra historia
      </Typography>
      <Typography> &nbsp; </Typography>
    </Box>
    <div>
    <ThemeProvider theme={theme2}>
    <CssBaseline />
    <Typography display="block" align="center" fontSize={25}>¡Conoce nuestra historia en  lo que comenzamos el próximo capítulo!</Typography>

    <VerticalTimeline>
  <VerticalTimelineElement
    className="vertical-timeline-element--work custom-element"
    date="Enero 2016"
    iconStyle={{ background: 'rgb(184, 134, 11)', color: '#fff' }}
    icon={<img src={isMobile ? get_image_url(itemData[12].img) : get_image_url(itemData[11].img)} alt="Icon" style={{ clipPath: 'inset(0 0 0 0 round 50%)', width: '100%', height: '100%' }}/>}
  >
    <h3 className="vertical-timeline-element-title">Donde todo comenzó</h3>
    <h4 className="vertical-timeline-element-subtitle">Salamanca, España</h4>
    <p>
      Geraldo y Kiara se conocieron en enero del 2016 como estudiantes de intercambio en la Universidad de Salamanca, España. Para su sorpresa, ambos vivieron toda la vida en Puerto Rico a cinco minutos de distancia. ("It was meant to be!")
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="Abril 2016"
    iconStyle={{ background: 'rgb(184, 134, 11)', color: '#fff' }}
    icon={<img src={isMobile ? get_image_url(itemData[18].img) : get_image_url(itemData[17].img)} alt="Icon" style={{ clipPath: 'inset(0 0 0 0 round 50%)', width: '100%', height: '100%' }}
    />}
  >
    <h3 className="vertical-timeline-element-title">Viaje amistoso</h3>
    <h4 className="vertical-timeline-element-subtitle">Barcelona, España</h4>
    <p>
      Kiara soñaba con ir al museo de Salvador Dalí en Figueres, y le comentó a Geraldo que pensaba ir sola. Geraldo le dijo a Kiara que "por su seguridad", él quería acompañarla, y se terminó invitando solo... Este se convirtió en el primer viaje juntos de muchos más.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work custom-element"
    date="Julio 2016"
    iconStyle={{ background: 'rgb(184, 134, 11)', color: '#fff' }}
    icon={<img src={isMobile ? get_image_url(itemData[14].img) : get_image_url(itemData[13].img)} alt="Icon" style={{ clipPath: 'inset(0 0 0 0 round 50%)', width: '100%', height: '100%' }}/>}

  >
    <h3 className="vertical-timeline-element-title">De vuelta a la realidad</h3>
    <h4 className="vertical-timeline-element-subtitle">Toa Baja, Puerto Rico</h4>
    <p>
      Geraldo regresa de Europa a Puerto  Rico luego de Kiara y la visita a su casa. En ese momento se dieron cuenta de que lo que comenzó en España, podía continuar en Puerto Rico.
    </p>
  </VerticalTimelineElement>
  
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="Enero 2018"
    iconStyle={{ background: 'rgb(184, 134, 11)', color: '#fff' }}
    icon={<img src={isMobile ? get_image_url(itemData[16].img) : get_image_url(itemData[15].img)} alt="Icon" style={{ clipPath: 'inset(0 0 0 0 round 50%)', width: '100%', height: '100%' }}/>}
  >
    <h3 className="vertical-timeline-element-title">Larga distancia</h3>
    <h4 className="vertical-timeline-element-subtitle">Puerto Rico</h4>
    <p>
      Cuando Geraldo se gradúa de ingeniería y Kiara de psicología, ella decide estudiar derecho en la UPR... pero Geraldo tenía una oferta de trabajo en Florida. Ante esto, deciden continuar su relación a larga distancia. Es aquí donde descubren lo bueno que son los vuelos en Spirit Airlines. 
    </p>
  </VerticalTimelineElement>

  <VerticalTimelineElement
    className="vertical-timeline-element--work custom-element"
    date="Marzo 2020"
    iconStyle={{ background: 'rgb(184, 134, 11)', color: '#fff' }}
    icon={<img src={isMobile ? get_image_url(itemData[2].img) : get_image_url(itemData[1].img)} alt="Icon" style={{ clipPath: 'inset(0 0 0 0 round 50%)', width: '100%', height: '100%' }}/>}
  >
    <h3 className="vertical-timeline-element-title">Covid-19</h3>
    <h4 className="vertical-timeline-element-subtitle">Melbourne, Florida</h4>
    <p>
      Cuando comienzan los cierres a causa del Covid, Kiara viaja "por un fin de semana" a Florida para visitar a Geraldo. Ese "fin de semana" se convirtió en cinco meses, los cuales disfrutaron al máximo entre juegos de mesa, maratones de Netflix, salidas a comer mantecado, y días de piscina. 
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="Octubre 2021"
    iconStyle={{ background: 'rgb(184, 134, 11)', color: '#fff' }}
    icon={<img src={isMobile ? get_image_url(itemData[8].img) : get_image_url(itemData[7].img)} alt="Icon" style={{ clipPath: 'inset(0 0 0 0 round 50%)', width: '100%', height: '100%' }}
    />}

  >
    <h3 className="vertical-timeline-element-title">¡Vámonos de "roadtrip"!</h3>
    <h4 className="vertical-timeline-element-subtitle">Florida - Seattle</h4>
    <p>
      El trabajo de Geraldo lo lleva hasta Seattle. Para llegar, deciden guiar desde Florida hasta Washington, haciendo paradas en varios estados, visitando  amistades y parques nacionales. Aunque nosotros llegamos bien, la Tacoma de Geraldo sufrió un accidente y se quedó en Montana.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work custom-element"
    date="18 de junio del 2022"
    iconStyle={{ background: 'rgb(184, 134, 11)', color: '#fff' }}
    icon={<img src={isMobile ? get_image_url(itemData[4].img) : get_image_url(itemData[3].img)} alt="Icon" style={{ clipPath: 'inset(0 0 0 0 round 50%)', width: '100%', height: '100%' }}/>}
  >
    <h3 className="vertical-timeline-element-title">¿Te quieres casar conmigo?</h3>
    <h4 className="vertical-timeline-element-subtitle">Seattle, Washington</h4>
    <p>
      Tras muchos sacrificios y largas distancias, habiendo cumplido sus metas de ser ingeniero y abogada, y estando establecidos finalmente en Seattle, Geraldo le propone matrimonio a Kiara frente a las cascadas Narada en el parque nacional de Mount Rainier en Washington. Evidentemente, Kiara aceptó.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    className="vertical-timeline-element--work"
    date="29 de diciembre del 2023"
    iconStyle={{ background: 'rgb(184, 134, 11)', color: '#fff' }}
    icon={<img src={isMobile ? get_image_url(itemData[6].img) : get_image_url(itemData[5].img)} alt="Icon" style={{ clipPath: 'inset(0 0 0 0 round 50%)', width: '100%', height: '100%' }}/>}
    >
    <h3 className="vertical-timeline-element-title">¡El gran día!</h3>
    <h4 className="vertical-timeline-element-subtitle">San Juan, Puerto Rico</h4>
    <p>
      Luego de siete años llenos de aventuras, estamos muy emocionados por celebrar con ustedes el comienzo del resto de nuestras vidas.
    </p>
  </VerticalTimelineElement>
  <VerticalTimelineElement
    iconStyle={{ background: 'rgb(184, 134, 11)', color: '#fff' }}
    icon={<img src={isMobile ? get_image_url(itemData[10].img) : get_image_url(itemData[9].img)} alt="Icon" style={{ clipPath: 'inset(0 0 0 0 round 50%)', width: '100%', height: '100%' }}/>}
    >
    <h3 className="vertical-timeline-element-title">To be continued...</h3>
    </VerticalTimelineElement>
</VerticalTimeline>
</ThemeProvider>
</div>
<Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
        <Typography> &nbsp; </Typography>
</ThemeProvider>
</React.Fragment>
</div>
  );
}
