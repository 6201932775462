import { useState, useEffect } from "react";
import RsvpForm from './RsvpForm';
import api from '../service';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';

const RsvpFormPage = () => {
  const { randomKey } = useParams();
  const [rsvpData, setRsvpData] = useState(null);
  const [validKey, setValidKey] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    api.getRsvpByKey(randomKey)
      .then(response => {
        setRsvpData(response);
        setValidKey(true);
      })
      .catch(err => {
        //console.error(err);
        setValidKey(false);
      });
  }, [randomKey]);

  if (rsvpData && rsvpData.error)
  {
    window.alert("Código no encontrado. Favor de utilizar el código localizado en su invitación.")
    navigate('/rsvp'); // Navigate to the desired URL, e.g., '/find-rsvp'
  }

  if (!validKey) {
    return null; // Render nothing until the key validation is complete
  }

  return <RsvpForm rsvpData={rsvpData} />;
};

export default RsvpFormPage;