import { useState } from "react";
import "./RsvpFormInput.css";

  const RsvpFormInput = (props) => {
  const [focused, setFocused] = useState(false);
  const { label, errormessage, onChange, max, name } = props;

  const handleFocus = (e) => {
    setFocused(true);
  };

  let options = [];

  if (name === "partyNumber") {
    const maxPartyNumber = max; // Set the maximum party number value

    for (let i = 1; i <= maxPartyNumber; i++) {
      options.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }
  }
  else if (name.includes("meal") ) {
    options = [
      { value: "", label: "Escoja una opción", disabled: true }, // Add disabled attribute to the first option
      { value: "Chicken", label: "Pollo" },
      { value: "Meat", label: "Carne" },
      { value: "Vegetarian", label: "Vegetariano" },
      { value: "Pescatarian", label: "Pescado" }
    ].map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ));
  }
  else if (name === "attending") {
    options = [
      { value: "", label: "Escoja una opción", disabled: true }, // Add disabled attribute to the first option
      { value: "SocialMedia", label: "Social Media" },
      { value: "Google", label: "Google" },
      { value: "Referral", label: "Referral" },
    ].map((option) => (
      <option key={option.value} value={option.value}>
        {option.label}
      </option>
    ));
  }

  return (
    <div className="formInput">
      <label>{label}</label>
      {name === "partyNumber" || name.includes("meal") || name === "attending" ? ( // Render select element for partyNumber
        <select
          {...props}
          name={name}
          onChange={onChange}
          onBlur={handleFocus}
          onFocus={() => setFocused(true)}
          focused={focused.toString()}
        >
          {options}
        </select>
      ) : ( // Render input element for other types
        <input
          {...props}
          onChange={onChange}
          onBlur={handleFocus}
          onFocus={() => setFocused(true)}
          focused={focused.toString()}
        />
      )}
      <span>{errormessage}</span>
    </div>
  );
};

export default RsvpFormInput;